import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { resourcesConfig } from '@proman/resources';
import { PrinterEntityInterface } from '@proman/resources/printer';
import { isArray, isDefined } from '@proman/utils';
import { ProductionOperation } from '@proman/interfaces/entity-interfaces';
import { ProductEntityInterface } from '@proman/resources/product';

@Component({
    selector: 'pm-barcode-printer-print-dialog',
    template: `
        <pro-dialog-title title="print_barcode"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column" style="min-height: 150px">
            <pm-barcode></pm-barcode>
            <pro-checkbox [value]="isAutoCloseDialog"
                         [config]="{ label: 'close_on_print' }"
                         (onChange)="setAutocloseDialog($event)"></pro-checkbox>
        </div>
        <pro-dialog-actions (callback)="print()"
                           variant="confirm">

            <pro-btn (onClick)="print(false)"
                    label="print_copy"
                    theme="accent">
            </pro-btn>
        </pro-dialog-actions>
    `
})

export class BarcodePrinterPrintDialogComponent {
    barcodePrinterEntity: PrinterEntityInterface;
    productEntity: ProductEntityInterface;
    isAutoCloseDialog: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<BarcodePrinterPrintDialogComponent>,
        private LocalStorage: LocalStorageService
    ) {
        this.barcodePrinterEntity = this.Entity.get('printer');
        this.productEntity = this.Entity.get('product');
    }

    print = (isClose?: boolean) => {

        if (isDefined(isClose)) {
            this.isAutoCloseDialog = isClose;
        }

        if (this.data.type === 'operation') {

            this.printOperation();

        } else if (this.data.type === this.productEntity.PRODUCTS) {

            this.printProduct();

        } else if (this.data.type === 'quant') {

            this.printQuant();

        } else if (this.data.type === 'warehouseLocation') {

            this.printWarehouseLocation();

        } else if (this.data.type === 'emit') {
            this.dialogRef.close({
                printer: this.LocalStorage.get('barcodePrinter'),
                barcode_template: this.LocalStorage.get('barcodeTemplate')
            });
        }

    };

    closeDialogCallback = () => {
        this.isAutoCloseDialog && this.dialogRef.close(1);
    };

    printOperation = () => {
        this.barcodePrinterEntity
            .printOperationTemplate({
                production_operation_id: isArray(this.data.operation) ? this.data.operation.map((op: ProductionOperation) => op.id) : this.data.operation.id,
                printer: this.LocalStorage.get('barcodePrinter'),
                barcode_template: this.LocalStorage.get('barcodeTemplate')
            })
            .then(this.closeDialogCallback);
    };

    printProduct = () => {
        this.barcodePrinterEntity
            .printProduct({
                id: this.data.product.id,
                printer: this.LocalStorage.get('barcodePrinter'),
                barcode_template: this.LocalStorage.get('barcodeTemplate')
            })
            .then(this.closeDialogCallback);
    };

    printQuant = () => {
        this.barcodePrinterEntity
            .printQuant({
                quant: this.data.quant.id,
                device: +this.LocalStorage.get('barcodePrinter'),
                barcode_template: this.LocalStorage.get('barcodeTemplate')
            })
            .then(this.closeDialogCallback);
    };

    printWarehouseLocation = () => {
        this.barcodePrinterEntity
            .printWarehouseLocation({
                warehouse_location: this.data.warehouseLocation.id,
                device: +this.LocalStorage.get('barcodePrinter'),
                barcode_template: this.LocalStorage.get('barcodeTemplate')
            })
            .then(() => this.dialogRef.close(1));
    };

    setAutocloseDialog(value: boolean) {
        this.isAutoCloseDialog = value;
    }

}
